import React, {useState, useEffect} from "react";
import classes from '../styles/history.module.css';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import Gradient from 'javascript-color-gradient';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { registerLocale } from "react-datepicker";
import ApexCharts from 'apexcharts'
import Charts from "react-apexcharts"
import Select, { components } from 'react-select';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import img_arrow from "../images/arrow.png"

import "react-datepicker/dist/react-datepicker.css";
import 'react-loading-skeleton/dist/skeleton.css'
import ru from 'date-fns/locale/ru';

import sorter from '../images/sorter.png'

import "../styles/history.css"

var apex_ru = require("apexcharts/dist/locales/ru.json")

const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Больше нет аккаунтов</span> 
      </components.NoOptionsMessage>
    );
  };

const My_Items = () => {
    const [Items, SetItems] = useState([]);
    const [ProfitSwitch, SetProfitSwitch] = useState(true);
    const [CurrentColumn, SetCurrentColumn] = useState("date_buy");
    const [Charts, SetCharts] = useState(null);
    const [DatesButtonId, SetDatesButtonId] = useState(1);
    const [Loading, SetLoading] = useState(true);
    const [FilterAll, SetFilterAll] = useState(false);
    const [PercentExpand, SetPercentExpand] = useState(0);
    const [UserId, SetUserId] = useState(0);
    const [FirstSale, SetFirstSale] = useState(new Date(2020, 0, 1));
    const [TopIndices, SetTopIndices] = useState({profit_percent: "-", profit_cash: "-", sales: "-", volume: "-", round: "-"});
    const navigate = useNavigate();

    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const date = new Date();
    // const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [startDate, setStartDate] = useState(new Date(date.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(new Date());
   
    function Fix() {
        console.log(document.querySelectorAll(".apexcharts-datalabel").length)
        if (document.querySelectorAll(".apexcharts-datalabel")[0] !== undefined) {
            let labels_length = document.querySelectorAll(".apexcharts-datalabel").length/2;
            if (labels_length % 1 !== 0) {
                labels_length -= 0.5;
            }
            document.querySelectorAll(".apexcharts-datalabel")[0].style.transform = "translate(30px, 0px)";
            document.querySelectorAll(".apexcharts-datalabel")[labels_length-1].style.transform = "translate(-30px, 0px)";
            document.querySelectorAll(".apexcharts-datalabel")[labels_length].style.transform = "translate(30px, 0px)";
            document.querySelectorAll(".apexcharts-datalabel")[labels_length*2-1].style.transform = "translate(-30px, 0px)";
        }
    }

    function RemoveProfitActive(e) {
        if (e.target.id !== "profit_expander") SetPercentExpand(0)
    }

    useEffect(() => {
        let options1 = {
            chart: {
                height: 250,
                type: 'area',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: false
                },
                zoom: {
                    enabled: false
                },
                animations: {
                    dynamicAnimation: {
                        enabled: true,
                        speed: 600
                    }
                },
                events: {
                    updated: () => {
                        Fix()
                    }
                },
                locales: [apex_ru], 
                defaultLocale: 'ru',
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            height: 200
                        },
                    }
                },
            ],
            series: [
                {
                    name: "Объем продаж",
                    // data: [7777.90, 5167.40, 14600.88, 19568.21, 13771.06, 13075.70, 19593.61, 15125.78, 13557.97, 27074.31, 41174.62, 29114.39]
                    // data: [7777.90, 5167.40, 14600.88, 19568.21, 13771.06, 13075.70, 19593.61, 15125.78, 13557.97, 27074.31]
                    data: [{x: 1711012589, y:2}, {x: 1711015589, y:2}, {x: 1711021589, y:2}, {x: 1711032589, y:2}, {x: 1711042589, y:2}]
                    // data: [{x: 1711012589, y:2}, {x: 1711015589, y:4}, {x: 1711021589, y:2}, {x: 1711032589, y:2}, {x: 1711042589, y:2}]
                },
                {
                    name: "Прибыль",
                    // data: [931.59, 529.06, 1619.35, 3208.96, 1066.03, 1241.36, 1494.37, 973.15, 1051.97, 2177.73, 3072.12, 2207.63]
                    // data: [931.59, 529.06, 1619.35, 3208.96, 1066.03, 1241.36, 1494.37, 973.15, 1051.97, 2177.73],
                    data: [{x: 1711012589, y:1}, {x: 1711015589, y:1}, {x: 1711021589, y:1}, {x: 1711032589, y:1}, {x: 1711042589, y:1}]
                    // data: [{x: 1711012589, y:1}, {x: 1711015589, y:2}, {x: 1711021589, y:1}, {x: 1711032589, y:1}, {x: 1711042589, y:1}]
                },
            ],
            colors: ['#6A6A6A', '#E3702C'],
            dataLabels: {
                enabled: true,
                style: {},
                offsetY: -6,
                background: {
                    enabled: false
                },
                formatter: function (value) {
                    return value + "$";
                },
            },
            stroke: {
                curve: 'straight',
                width: 1.5,
                linecap: "square"
            },
            grid: {
                show: false,
                padding: {
                    left: 0,
                    right: 0,
                    bottom: -12
                }
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    show: false,
                    style: {
                        colors: "#6A6A6A"
                    },
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false,
                },
                tooltip: {
                    enabled: false
                },
            },
            yaxis: [
                {
                    show: false,
                    // min: -Math.max(...Sales.volume)/2,
                },
                {
                    show: false,
                    opposite: true,
                    // max: Math.max(...Sales.profit)*2
                }
            ],
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                floating: true,
                // showForSingleSeries: false,
                // showForNullSeries: true,
                // showForZeroSeries: true,
                labels: {
                    colors: "rgb(255, 255, 255)",
                },
                markers: {
                    height: 12,
                    width: 12
                },
                offsetY: -36,
                offsetX: -5,
                fontSize: "16px",
                fontFamily: "Inter"
            },
            title: {
                text: "⠀",
                align: 'left'
              },
            fill: {
                type: 'gradient',
                gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0,
                stops: [0, 100],
                gradientToColors: ["#1f1f1f", "#1f1f1f"]
                }
            },
            tooltip: {
                x: {
                    format: 'MMM d',
                },
                y: {
                    formatter: function (value) {
                        return value + "$";
                    },
                }
            }
        };

        let options2 = {
            chart: {
                height: 280,
                type: 'line',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                animations: {
                    dynamicAnimation: {
                        enabled: true,
                        speed: 600
                    }
                },
                locales: [apex_ru], 
                defaultLocale: 'ru',
            },
            series: [
                {
                    name: "Процент прогнозируемый",
                    data: [{x:1704451053000, y:10}, {x:1704471363000, y:10}, {x:1704491673000, y:10}]
                },
                {
                    name: "Процент фактический",
                    data: [{x:1704451053000, y:9}, {x:1704471363000, y:9}, {x:1704491673000, y:9}]
                },
            ],
            colors: ['#B1B1B1', '#E3702C'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
                width: 2,
                linecap: "square"
            },
            grid: {
                show: false,
                padding: {
                    left: 0
                }
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    show: true,
                    style: {
                        colors: "#6A6A6A"
                    },
                    offsetY: -4,
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: 'd MMM',
                        day: 'd MMM',
                        hour: 'HH:mm'
                    }
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    color: "#6A6A6A"
                },
                tooltip: {
                    enabled: false
                },
             
            },
            yaxis: {
                show: true,
                axisBorder: {
                    show: true,
                    color: "#6A6A6A"
                },
                labels: {
                    style: {
                        colors: "#6A6A6A"
                    },
                    offsetX: -12
                },
            },
            legend: {
                show: false,
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            height: 200
                        },
                    }
                },
                {
                    breakpoint: 1500,
                    options: {
                        chart: {
                            height: 240
                        },
                    }
                }
            ],
            title: {
                text: "Проценты прибыли",
                align: 'left',
                style: {
                    color: "rgb(255, 255, 255)",
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: 16
                },
                offsetX: 37,
                offsetY: 20,
            },
            tooltip: {
                x: {
                    format: 'MMM d',
                },
                y: {
                    formatter: function (value) {
                        return value + "%";
                    },
                }
            }
        };

        let options3 = {
            chart: {
                height: 280,
                type: 'bar',
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                animations: {
                    dynamicAnimation: {
                        enabled: true,
                        speed: 600
                    }
                },
                locales: [apex_ru], 
                defaultLocale: 'ru',
            },
            series: [
                {
                    name: "Продажи",
                    data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10]
                }
            ],
            colors: ['#E3702C', '#6A6A6A'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'straight',
                width: 1,
                linecap: "square"
            },
            grid: {
                show: false,
                padding: {
                    left: 40,
                    bottom: -6,
                }
            },
            xaxis: {
                categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
                labels: {
                    show: true,
                    style: {
                        colors: "#6A6A6A"
                    },
                    offsetY: -4
                },
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    color: "#6A6A6A",
                },
                tooltip: {
                    enabled: false
                },
            },
            yaxis: {
                show: true,
                axisBorder: {
                    show: true,
                    color: "#6A6A6A",
                    // offsetX: -4,
                },
                labels: {
                    style: {
                        colors: "#6A6A6A"
                    },
                    offsetX: 28
                },
                floating: true,
            },
            legend: {
                show: false,
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            height: 200
                        },
                    }
                },
                {
                    breakpoint: 1500,
                    options: {
                        chart: {
                            height: 240
                        },
                    }
                }
            ],
            title: {
                text: "Продажи",
                align: 'left',
                style: {
                    color: "rgb(255, 255, 255)",
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: 16
                },
                offsetX: 40,
                offsetY: 20,
            },
            fill: {
                opacity: 0.2
            }
        };

        let options4 = {
            chart: {
                height: 280,
                type: 'donut',
                stacked: true,
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 600
                    }
                },
                locales: [apex_ru], 
                defaultLocale: 'ru',
            },
            // series: [34, 16, 5],
            // series: [10, 10, 15],
            series: [1, 1, 1, -3],
            colors: ['#E37534', '#B1B1B1', '#636363'],
            labels: ['0-1 день', '2-3 дня', '3> дней'],
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    customScale: 0.8,
                    donut: {
                        size: "80%",

                        labels: {
                            show: true,
                            value: {
                                show: true,
                                fontSize: '22px',
                                fontWeight: 500,
                                color: '#ffffff',
                                // offsetY: -10
                            },
                            total: {
                                show: true,
                                label: "Всего",
                                fontSize: "22px",
                                fontWeight: "bold",
                                color: "#fff",
                            },
                        },
                    },
                },
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        chart: {
                            height: 200
                        },
                    }
                },
                {
                    breakpoint: 1500,
                    options: {
                        chart: {
                            height: 240
                        },
                    }
                }
            ],
            legend: {
                show: false,
            },
            stroke: {
                width: 3,
                colors: ["#1f1f1f"]
            },
            grid: {
                show: false,
                padding: {
                }
            },
            title: {
                text: "Продолжительность",
                align: 'center',
                style: {
                    color: "rgb(255, 255, 255)",
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: 16
                },
                offsetX: 0,
                offsetY: 20,
            }
        };

        let chart1 = new ApexCharts(document.querySelector("#history_chart_sales"), options1);
        let chart2 = new ApexCharts(document.querySelector("#history_chart_profits"), options2);
        let chart3 = new ApexCharts(document.querySelector("#history_chart_sales_dates"), options3);
        let chart4 = new ApexCharts(document.querySelector("#history_chart_sales_duration"), options4);
        SetCharts({chart1: chart1, chart2: chart2, chart3: chart3, chart4: chart4})

        chart1.render();
        chart2.render();
        chart3.render();
        chart4.render();

        // LoadHistory(charts, dateStart, dateEnd);
        // setTimeout(() => {LoadHistory(charts, dateStart, dateEnd)}, 3000000)
        window.scrollTo(0, 0);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        document.addEventListener("click", RemoveProfitActive)
    }, [])

    async function DatesButton(e) {
        let id = e.target.id;
        const date = new Date();
        const dateEnd = new Date();
        setEndDate(dateEnd);
        if (id === "1") {
            const dateStart = new Date(date.getFullYear(), 0, 1);
            setStartDate(dateStart);
            SetDatesButtonId(1);
        }
        else if (id === "2") {
            const dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
            setStartDate(dateStart);
            SetDatesButtonId(2);
        }
        else if (id === "3") {
            const dateStart = new Date();
            setStartDate(dateStart);
            SetDatesButtonId(3);
        }
        else if (id === "4") {
            const dateStart = FirstSale;
            setStartDate(dateStart);
            SetDatesButtonId(4);
        }
    }

    async function LoadHistory(charts, dateStart, dateEnd, accounts, sort = "date_buy", direction="desc") {
        try {
            console.log(selectedOptions);
            let acc_opt;
            let acc_flag = false;
            if (accounts !== undefined && accounts.length) {
                acc_opt = "&accounts=" + accounts.map((option) => option.value).join(",");
                acc_flag = true;
            } else if (selectedOptions.length !== 0){
                acc_opt = "&accounts=" + selectedOptions.map((option) => option.value).join(",");
                acc_flag = true;
            }
            let r = await fetch(`https://artempos.ru/api/history?key=${Cookies.get("session")}&sort=${sort}&direction=${direction}&date_start=${dateStart.getTime()}&date_end=${dateEnd.getTime()}${acc_flag ? acc_opt : ''}&all=${FilterAll ? "yes": "no"}`);
            r = await r.json();
            if (r.status) {
                let accounts = []
                r.data.accounts.forEach(account => {
                    accounts.push({value: account, label: account})
                });
                setOptions(accounts);
                SetItems([...r.data.items.sort((a, b) => {
                    let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                    let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                    if (a.timestamp_buy === null) {
                        if (days_diff_a < days_diff_b) return 1
                        else return -1
                    }
                    if (b.timestamp_buy === null) return 1
                    if (a.timestamp_buy === b.timestamp_buy) return 0
                    if (a.timestamp_buy > b.timestamp_buy) return -1
                    if (a.timestamp_buy < b.timestamp_buy) return 1
                })]);
                SetLoading(false);
                SetFirstSale(new Date(r.data.first_sale*1000))
                SetTopIndices({profit_percent: r.data.top.profit_percent, profit_cash: r.data.top.profit_cash, sales: r.data.top.sales, volume: r.data.top.volume, round: r.data.top.round})
                SetUserId(r.data.user_id)

                let max_volumes = 0;
                let volumes = r.data.volume.map((i) => {
                    if (i[0] > max_volumes) max_volumes = i[0];
                    return {x: i[2]*1000, y: i[0]}
                });
                let max_profits = 0;
                let profits = r.data.volume.map((i) => {
                    if (i[1] > max_profits) max_profits = i[1];
                    return {x: i[2]*1000, y: i[1]}
                });
                charts.chart1.updateSeries([
                    {name: "Объем продаж", data: volumes},
                    {name: "Прибыль", data: profits},
                ])  

                // charts.chart1.updateSeries([
                //     {name: "Объем продаж", data: data1},
                //     {name: "Прибыль", data: r.data.volume.profit}
                // ]);
                charts.chart1.updateOptions({
                    yaxis: [
                        {show: false, min: -max_volumes/2},
                        {show: false, max: max_profits*2, opposite: true}
                    ],
                })

                let percent_1 = r.data.percents.map((i) => {
                    return {x: i[2]*1000, y: i[0]}
                });
                let percent_2 = r.data.percents.map((i) => {
                    return {x: i[2]*1000, y: i[1]}
                });
                charts.chart2.updateSeries([
                    {
                        name: "Процент прогнозируемый",
                        data: percent_1
                    },
                    {
                        name: "Процент фактический",
                        data: percent_2
                    },
                ]);
                charts.chart3.updateSeries([
                    {
                        name: "Продажи",
                        data: r.data.sales
                    },
                    {
                        name: "Неуспешные продажи",
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },
                ]);
                charts.chart4.updateSeries(r.data.duration);
            } else {
                if (r.error === "wrong session") navigate('/login', { replace: true });
                toast.error(r.error);
            }
        } catch (error) {
            toast.error("Не получилось выполнить запрос, ошибка: " + error);
        }
    }

    const gradentParams = ['#FF0000', '#EBFF00', 'BDFF00', '#90EE90', '#00FFB2'];
    const colorCellFromValue = (params, value, midpoint = 29) => {
        value += 2;
        if (value <= 0) {
            value = 1;
        }
        return new Gradient()
          .setColorGradient(...params)
          .setMidpoint(midpoint).getColor(value);
      };

    function Sort(id) {
        console.log(Items)

        if (id === CurrentColumn) {
            if (id === "name") SetItems([...Items.sort((a, b) => a.name < b.name ? 1 : -1)]);
            else if (id === "date_buy") SetItems([...Items.sort((a, b) => {
                let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                if (a.timestamp_buy === null) {
                    if (days_diff_a > days_diff_b) return 1
                    else return -1
                }
                if (b.timestamp_buy === null) return 1
                if (a.timestamp_buy === b.timestamp_buy) return 0
                if (a.timestamp_buy > b.timestamp_buy) return 1
                if (a.timestamp_buy < b.timestamp_buy) return -1
            })]);
            else if (id === "date_sell") SetItems([...Items.sort((a, b) => a.timestamp_sell > b.timestamp_sell ? 1 : -1)]);
            else if (id === "price_buy") SetItems([...Items.sort((a, b) => a.price_buy < b.price_buy ? 1 : -1)]);
            else if (id === "price_sell") SetItems([...Items.sort((a, b) => a.price_sell < b.price_sell ? 1 : -1)]);
            else if (id === "profit") {
                if (ProfitSwitch) SetItems([...Items.sort((a, b) => a.profit_cash < b.profit_cash ? 1 : -1)]);
                else SetItems([...Items.sort((a, b) => a.profit_percent < b.profit_percent ? 1 : -1)]);
            } 
            else if (id === "account") SetItems([...Items.sort((a, b) => a.account < b.account ? 1 : -1)]);
            else if (id === "market_buy") SetItems([...Items.sort((a, b) => a.market_buy < b.market_buy ? 1 : -1)]);
            else if (id === "market_sell") SetItems([...Items.sort((a, b) => {
                if (a.market_sell === b.market_sell) return 0
                if (a.market_sell < b.market_sell) return 1
                if (a.market_sell > b.market_sell) return -1
                if (a.market_sell === null) return -1
                if (b.market_sell === null) return 1
            })]);
            SetCurrentColumn("");
        } else {
            if (id === "name") SetItems([...Items.sort((a, b) => a.name > b.name ? 1 : -1)]);
            else if (id === "date_buy") SetItems([...Items.sort((a, b) => {
                let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                if (a.timestamp_buy === null) {
                    if (days_diff_a < days_diff_b) return 1
                    else return -1
                }
                if (b.timestamp_buy === null) return 1
                if (a.timestamp_buy === b.timestamp_buy) return 0
                if (a.timestamp_buy > b.timestamp_buy) return -1
                if (a.timestamp_buy < b.timestamp_buy) return 1
            })]);
            else if (id === "date_sell") SetItems([...Items.sort((a, b) => a.timestamp_sell < b.timestamp_sell ? 1 : -1)]);
            else if (id === "price_buy") SetItems([...Items.sort((a, b) => a.price_buy > b.price_buy ? 1 : -1)]);
            else if (id === "price_sell") SetItems([...Items.sort((a, b) => a.price_sell > b.price_sell ? 1 : -1)]);
            else if (id === "profit") {
                if (ProfitSwitch) SetItems([...Items.sort((a, b) => a.profit_percent > b.profit_percent ? 1 : -1)]);
                else SetItems([...Items.sort((a, b) => a.profit_cash > b.profit_cash ? 1 : -1)]);
            }
            else if (id === "account") SetItems([...Items.sort((a, b) => a.account > b.account ? 1 : -1)]);
            else if (id === "market_buy") SetItems([...Items.sort((a, b) => a.market_buy > b.market_buy ? 1 : -1)]);
            else if (id === "market_sell") SetItems([...Items.sort((a, b) => {
                if (a.market_sell === b.market_sell) return 0
                if (a.market_sell > b.market_sell) return 1
                if (a.market_sell < b.market_sell) return -1
                if (a.market_sell === null) return 1
                if (b.market_sell === null) return -1
            })]);
            SetCurrentColumn(id);
        }
    }

    function Sort_handler(e) {
        // console.log(e.currentTarget);
        const id = e.currentTarget.getAttribute("column");
        // console.log(id, CurrentColumn);
        Sort(id)
    }

    function PercentExpander(e) {
        console.log(e)
        document.
        e.currentTarget.className = classes.percent_active
        e.target.innerHTML = '<span>bar</span>'
    }

    let percent_flag = false

    const items = Items.map((item) => {
        let place_time_buy
        let place_color = "rgb(255, 255, 255)"
        if (item.date_buy) {
            place_time_buy = item.date_buy
        } else {
            if (item.time_buy_tradeban) {
                let days_diff = Math.floor((item.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                place_time_buy = `${item.time_buy_tradeban} (${days_diff})`
                place_color = "#9C9C9C"
            } else {
                place_time_buy = "-"
            }
        }

        return (
            <tr className={classes.item} key={item.id}>
                <th style={{textAlign: "left", paddingLeft: 0}}>{item.name}</th>
                <td style={{color: place_color}}>{place_time_buy}</td>
                <td>{item.date_sell ? item.date_sell : "-"}</td>
                <td>{item.price_buy}{item.currency_buy === "USD" ? "$" : "¥"}</td>
                <td style={{ opacity: item.date_sell ? 1 : 0.4 }}>{item.price_sell ? item.currency_sell === "USD" ? item.price_sell+"$" : item.price_sell+"¥" : "-"}</td>
                <td style={{position: 'relative'}}>
                    <div
                        id="profit_expander"
                        style={
                            {
                                display: 'flex',
                                width: 'fit-content',
                                cursor: 'pointer',
                                minWidth: '0',
                                boxSizing: 'border-box',
                                alignItems: 'center',
                                color: item.profit_percent !== null ? colorCellFromValue(gradentParams, item.profit_percent) : '',
                                opacity: item.date_sell ? 1 : 0.4,
                                transition: 'opacity 0.3s, background-color 0.3s, border-radius 0.3s',
                            }
                        }
                        className={item.id === PercentExpand ? classes.percent_active : ''}
                        onClick={() => {SetPercentExpand(item.id)}}
                    >
                    {
                        item.id === PercentExpand && (
                            item.market_profits.sort((a, b) => a.profit_percent > b.profit_percent ? 1 : -1).sort((a, b) => a.important === false ? 1 : -1).map((el) => {
                                if (!el.important && percent_flag === false) {
                                    percent_flag = true
                                    return (
                                        <>
                                            <div style={{ width: '100%', height: '1px', backgroundColor: '#9C9C9C', padding: '0', margin: '4px 0' }}></div>
                                            <div style={{ color: '#FFFFFF' }}><span style={{color: colorCellFromValue(gradentParams, el.profit_percent)}}>{el.profit_percent}%</span><span> - {el.market}</span></div>
                                        </>                                        
                                    )
                                } else {
                                    return <div style={{ color: '#FFFFFF' }}><span style={{color: colorCellFromValue(gradentParams, el.profit_percent)}}>{el.profit_percent}%</span><span> - {el.market}</span></div>
                                }
                            })
                        )
                    }{
                        item.id !== PercentExpand && (
                            item.profit_percent !== null ? ProfitSwitch ? item.profit_percent + "%" : item.currency_sell === "USD" ? item.profit_cash + "$": item.profit_cash + "¥" : "-" 
                        )
                    }{
                        item.id !== PercentExpand && <img id="profit_expander" style={{ height: '20px', filter: 'invert(100%)', transform: 'rotate(180deg)' }} src={img_arrow} />
                    }
                    </div>
                </td>
                <td>{item.account}</td>
                <td>{item.market_buy ? item.market_buy : "-"}</td>
                <td style={{paddingRight: 0}}>{item.market_sell ? item.market_sell : "-"}</td>
            </tr>
        )
    })

    const [Options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])

    let account_menu_open = false;

    const styles = {
        control: (provided, state) => {
            account_menu_open = state.isFocused ? true : false;
            return {
                ...provided,
                boxShadow: "none",
                border: "1px solid #828282",
                color: "#fff",
                minHeight: "40px",
                maxHeight: state.isFocused ? "110px" : "40px",
                width: "220px",
                zIndex: "2",
                backgroundColor: "#1f1f1f",
                '&:hover': state.isFocused ? { borderColor: '#E3702C' } : {borderColor: '#B3B3B3'},
                cursor: "pointer",
            }
        },

        SelectContainer: (provided, state) => ({
            ...provided,
            height: "100px"
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            // maxHeight: '100px',
            maxHeight: account_menu_open ? '100px' : '38px',
            overflow: "auto",
            '&::-webkit-scrollbar': {
                width: "0px"
            },
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            // maxHeight: state.isFocused ? '100px' : '30px',
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            // maxHeight: state.isFocused ? '100px' : '30px',
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#1f1f1f",
            border: "1px solid #828282",
            zIndex: "2",
        }),
        option: (provided, state) => ({
            ...provided,
            color: "#fff",
            // backgroundColor: state.isFocused ? "#CD7D4E" : "none"
            backgroundColor: state.isFocused ? "#E37534" : "none",
            // cursor: "pointer",
        }),
        input: (provided, state) => ({
            ...provided,
            color: "#fff",
        }),
        // noOptionsMessage: (provided, state) => ({
        //     ...provided,
        //     color: "#fff",
        //     // backgroundColor: state.isFocused ? "#CD7D4E" : "none"
        //     backgroundColor: state.isFocused ? "#E37534" : "none",
        //     // cursor: "pointer",
        // }),
      
        // input: (provided, state) => ({
        // ...provided,
        // margin: '0px',
        // }),
        // indicatorSeparator: state => ({
        // display: 'none',
        // }),
        // indicatorsContainer: (provided, state) => ({
        // ...provided,
        // height: '30px',
        // }),
        // option: (provided, state) => ({
        //     ...provided,
        //     color: "#fff",
        // }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: "#636363",
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: "#fff",
        }),
    }

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            let date1 = new Date(Math.min.apply(null, [startDate, endDate]))
            let date2 = new Date(Math.max.apply(null, [startDate, endDate]))
            date1.setHours(0, 0, 0)
            date2.setHours(23, 59, 59)

            SetLoading(true);
            SetTopIndices({profit_percent: "-", profit_cash: "-", sales: "-", volume: "-", round: "-"})
            
            if (Charts !== null) {
                LoadHistory(Charts, date1, date2, selectedOptions);
            }
        }

    }, [Charts, FilterAll, startDate, endDate, selectedOptions, DatesButtonId])

    const onDatesChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        console.log(start, end)
        if (start !== null && end !== null) {SetDatesButtonId(10);}
      };
    
    return (
        <main>
            <ToastContainer autoClose={2000} position='top-left' draggablePercent={40}/>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <div className={classes.head}>
                        <div className={classes.top}>
                            <div className={classes.calendar_holder}>
                                <span>Период:</span>
                                <div className={classes.calendar_buttons}>
                                    <button id="4" onClick={DatesButton} className={DatesButtonId === 4 ? classes.active : ""}>Всё</button>
                                    <button id="1" onClick={DatesButton} className={DatesButtonId === 1 ? classes.active : ""}>Год</button>
                                    <button id="2" onClick={DatesButton} className={DatesButtonId === 2 ? classes.active : ""}>Месяц</button>
                                    <button id="3" onClick={DatesButton} className={DatesButtonId === 3 ? classes.active : ""}>День</button>
                                    <div style={{display: "flex", height: 26, width: 1, backgroundColor: "#828282", margin: "0 10px 0 6px"}}></div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {SetDatesButtonId(10);setStartDate(date)}}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={FirstSale}
                                        maxDate={date}
                                        calendarClassName = "history_page_calendar"
                                        // dayClassName={(date) => classes.calendar_days}
                                        locale={ru}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                    <div style={{display: "flex", height: 1, width: 10, backgroundColor: "#828282", margin: "0 8px"}}></div>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => {SetDatesButtonId(10);setEndDate(date)}}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={FirstSale}
                                        maxDate={date}
                                        calendarClassName = "history_page_calendar"
                                        // dayClassName={(date) => classes.calendar_days}
                                        locale={ru}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                
                            </div>
                            <div className={classes.calendar_statistic}>
                                <span>Общие показатели:</span>
                                <div className={classes.statistic}>
                                   <span>Прибыль %</span>
                                   <span>{TopIndices.profit_percent}</span>
                                </div>
                                <div className={classes.statistic} style={{width: 85}}>
                                   <span>Прибыль $</span>
                                   <span>{TopIndices.profit_cash}</span> 
                                </div>
                                <div className={classes.statistic}>
                                   <span>Продажи</span>
                                   <span>{TopIndices.sales}</span>
                                </div>
                                <div className={classes.statistic}>
                                   <span>Объем продаж</span>
                                   <span>{TopIndices.volume}</span>
                                </div>
                                <div className={classes.statistic} style={{width: 85}}>
                                   <span>Круг</span>
                                   <span>{TopIndices.round}</span>
                                </div>
                            </div>
                            <div className={classes.accounts}>
                                <span>Фильтр:</span>
                                <Select
                                    styles={styles} 
                                    className="select-custom-class"
                                    closeMenuOnSelect={false}
                                    onChange={(opt) => {setSelectedOptions(opt);console.log("asdasd")}}
                                    defaultValue={selectedOptions}
                                    options={Options}
                                    isMulti
                                    maxMenuHeight={200}
                                    components={{ NoOptionsMessage }}
                                    // isDisabled={console.log("close")}
                                    placeholder={'Аккаунты'}
                                />
                            <button onClick={() => {SetFilterAll(!FilterAll)}} className={FilterAll ? classes.active : ""} style={UserId !== 1 ? {display: "None"} : {}}>Всё</button>
                            </div>
                        </div>
                        <div className={classes.graphs_holder}>
                            <DatePicker
                                selected={startDate}
                                onChange={onDatesChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                calendarClassName = "history_page_calendar history_page_calendar2"
                                dayClassName={(date) => classes.calendar_days}
                                locale={ru}
                                dateFormat="dd/MM/yyyy"
                            />
                            <div className={classes.chart_profits_holder}>
                                {Loading && (
                                    <SkeletonTheme baseColor="rgba(31, 31, 31, 0.5)" highlightColor="rgba(60, 60, 60, 0.5)">
                                        <Skeleton
                                            height="100%"
                                            width="100%"
                                        />      
                                    </SkeletonTheme>
                                )}
                               
                                <div id="history_chart_profits">
                                </div>
                            </div>
                            <div className={classes.chart_sales_dates_holder}>
                                {Loading && (
                                    <SkeletonTheme baseColor="rgba(31, 31, 31, 0.5)" highlightColor="rgba(60, 60, 60, 0.5)">
                                        <Skeleton
                                            height="100%"
                                            width="100%"
                                        />      
                                    </SkeletonTheme>
                                )}
                                <div id="history_chart_sales_dates">
                                </div>
                            </div>
                            <div className={classes.chart_sales_duration_holder}>
                                {Loading && (
                                    <SkeletonTheme baseColor="rgba(31, 31, 31, 0.5)" highlightColor="rgba(60, 60, 60, 0.5)">
                                        <Skeleton
                                            height="100%"
                                            width="100%"
                                        />      
                                    </SkeletonTheme>
                                )}
                                <div id="history_chart_sales_duration">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.chart_sales_holder}>
                        {Loading && (
                            <SkeletonTheme baseColor="rgba(31, 31, 31, 0.5)" highlightColor="rgba(60, 60, 60, 0.5)">
                                <Skeleton
                                    height="100%"
                                    width="100%"
                                />      
                            </SkeletonTheme>
                        )}
                        <div id='history_chart_sales'></div>
                    </div>
                    <div className={classes.table_options_holder}>
                        <span>История продаж</span>
                        <div className={classes.table_options}>
                            <div className={classes.option} onClick={() => SetProfitSwitch(!ProfitSwitch)} action="percent/cash">%/$</div>
                        </div>
                    </div>
                    <div className={classes.table_holder}>
                        <table className={classes.table}>
                            <thead className={classes.thead}>
                                <tr>
                                    <th column="name" onClick={Sort_handler} style={{textAlign: "left", paddingLeft: 0}}><span>Название</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="date_buy" onClick={Sort_handler}><span>Дата покупки</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="date_sell" onClick={Sort_handler}><span>Дата продажи</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="price_buy" onClick={Sort_handler}><span>Покупка</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="price_sell" onClick={Sort_handler}><span>Продажа</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="profit" onClick={Sort_handler}><span>Прибыль</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="account" onClick={Sort_handler}><span>Аккаунт</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="market_buy" onClick={Sort_handler}><span>Куплен</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="market_sell" style={{paddingRight: 0}} onClick={Sort_handler}><span>Продан</span><img src={sorter} className={classes.sorter}/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default My_Items